import React, { FC } from 'react';
import { Link } from 'gatsby';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Bike } from '../../types/Bike';
import { constructBikeTitle } from '../../utils/constructBikeTitle';

type DataProps = Record<string, unknown> & Bike;

const useStyles = makeStyles((theme: Theme) => ({
  rootCard: {
    maxWidth: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: '0px 5px 10px rgba(187, 187, 187, 0.25)',
  },
  rootAvailabilityText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'right',
  },
  rootPriceText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'right',
  },
  rootCardMedia: {
    objectFit: 'contain',
  },
  rootCardContent: {
    backgroundColor: theme.palette.grey[100],
    height: '100px',
  },
}));

const VerticalCard: FC<DataProps> = (props: Bike) => {
  const classes = useStyles();
  const { mainImg, modelId, price24h } = props;
  const title = constructBikeTitle(props);

  return (
    <Card className={classes.rootCard}>
      <CardActionArea
        component={Link}
        to={`detail/${modelId}`}
        disableRipple
        disableTouchRipple
      >
        <CardMedia
          className={classes.rootCardMedia}
          component="img"
          alt={title}
          height="140"
          image={mainImg.split('.jpg')[0] + '_500x500.jpg'}
          title={title}
        />
        <CardContent className={classes.rootCardContent}>
          <Typography gutterBottom variant="h6" component="h3">
            {title}
          </Typography>

          <Typography
            className={classes.rootPriceText}
            variant="subtitle1"
            component="div"
          >
            {price24h} Kč/den
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default VerticalCard;
