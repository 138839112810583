import { withStyles, Theme } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilterTabs = withStyles((_theme: Theme) => ({
  indicator: {
    backgroundColor: 'transparent',
  },
}))(Tabs);

export default FilterTabs;
