import React, { FC } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  rootStepCard: {
    maxWidth: 300,
    margin: 'auto',
    textAlign: 'center',
  },
  rootStepNumber: {
    fontSize: theme.typography.pxToRem(60),
    fontWeight: theme.typography.fontWeightBold,
    textShadow: `8px 8px 0 ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(6),
  },
  rootStepNumberText: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

type DataProps = Record<string, unknown>;
type StepCardDataProps = {
  text: string;
  stepNumber: number;
};

const StepCard: FC<StepCardDataProps> = ({ text, stepNumber }) => {
  const classes = useStyles();
  return (
    <Box className={classes.rootStepCard}>
      <Typography component="div" className={classes.rootStepNumber}>
        {stepNumber}
      </Typography>
      <Typography component="div" className={classes.rootStepNumberText}>
        {text}
      </Typography>
    </Box>
  );
};

const HowIsItWorkSection: FC<DataProps> = () => {
  return (
    <Box component="section" py={10}>
      <Container>
        <Box mb={4} textAlign="center">
          <Typography component="h2" variant="h3">
            Jak to funguje? *
          </Typography>
        </Box>

        <Box mb={5} textAlign="center">
          <Typography component="div" variant="subtitle1">
            * Usilovně pracujeme na nové verzi webu, která Vám
            <br />
            usnadní celkový proces vypůjčení kola.
          </Typography>
        </Box>

        <Grid container alignItems="flex-start" justify="center" spacing={3}>
          <Grid item xs={12} sm={10} md={4}>
            <StepCard
              stepNumber={1}
              text="Vyberte si u nás kolo a pošlete poptávku pomocí dotazníku."
            />
          </Grid>

          <Grid item xs={12} sm={10} md={4}>
            <StepCard
              stepNumber={2}
              text="Naše specialistka s Vámi po telefonu doladí detaily."
            />
          </Grid>

          <Grid item xs={12} sm={10} md={4}>
            <StepCard
              stepNumber={3}
              text="Náš tým vám kolo dopraví na domluvené místo (v rámci BeRider zóny)"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowIsItWorkSection;
