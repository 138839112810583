import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { BikeTypeValue } from '../../types/other';

const commentsObj = {
  mtbhardtail: {
    title: 'HARDTAILS - soustředěná jednoduchost',
    body:
      'Hardtails nabízejí jednoduchost, se kterou celoodpružená kola prostě nemohou konkurovat. Díky pouze přednímu odpružení umožňuje výrobcům jízdních kol udělat neuvěřitelně lehký rám.',
  },
  mtbfull: {
    title: 'MTB Full - technologie jízdních kol v celé své kráse',
    body:
      'Plné odpružení vám poskytne jistotu a zacházení, které HARDTAIL nikdy nedokáže',
  },
  ecross: {
    title: 'E-CROSS - skvělé dobrodružství, skvělá zábava',
    body:
      'Nemusíte být zkušeným jezdcem, abyste si mohli užít rekreační zábavu v přírodě. Odolný - bez ohledu na počasí - je navržen tak, aby zvládl jízdu v terénu, zatímco vy se ponoříte do scenérie. Jede jako sen a vy se budete cítit plni energie.',
  },
  kids: {
    title: 'DĚTSKÁ - kola i pro ty nejmenší',
    body: '',
  },
};

const useStyles = makeStyles(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_theme: Theme) => {
    return {
      centerNarrowText: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
      },
    };
  },
);

function selectText(textType: BikeTypeValue) {
  if (commentsObj[textType]) {
    return commentsObj[textType];
  } else {
    return {
      title: 'něco se pokazilo',
      body: '',
    };
  }
}

export const CommentBox = (props: {
  categoryType: BikeTypeValue;
}): JSX.Element => {
  const { categoryType } = props;
  const classes = useStyles();
  const comment = selectText(categoryType);

  return (
    <div className="CommentBox">
      <Box className={classes.centerNarrowText}>
        <Grid item xs={12} sm={9} md={6}>
          <h1>{comment.title}</h1>
          <p>{comment.body}</p>
        </Grid>
      </Box>
    </div>
  );
};
