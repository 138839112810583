import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { FC } from 'react';
import { BlackButton } from '../../components/Button';
import { CirclePlusIcon } from '../../components/SvgIcon';

type DataProps = Record<string, unknown>;

const useStyles = makeStyles((theme: Theme) => ({
  backgroundImage: {
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 60px',
    backgroundSize: 'contain',
    display: 'flex',
    alignItems: 'stretch',

    '&::before, &::after': {
      backgroundPosition: 'center 60px',
      backgroundSize: 'contain',
    },

    [theme.breakpoints.up('md')]: {
      backgroundPosition: '100px 60px !important',
      backgroundSize: 'cover !important',

      '&::before, &::after': {
        backgroundPosition: '100px 60px !important',
        backgroundSize: 'cover !important',
      },
    },
  },
  rootBoxGradient: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',

    [theme.breakpoints.up('md')]: {
      backgroundImage: `linear-gradient(to right, #fff 45%, transparent 45%)`,
      alignItems: 'center',
    },
  },
  rootGridItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  rootContainer: {
    background: theme.palette.common.white,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      background: 'transparent',
    },
  },
}));

const HomepageHeroSection: FC<DataProps> = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query HeroSectionQuery3 {
      heroImage: file(relativePath: { eq: "heros/hero_image_woods.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1080) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const handleScrollClick = () => {
    const anchor = document.getElementById('list-bikes-section');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <BackgroundImage
      Tag="section"
      fluid={data.heroImage.childImageSharp.fluid}
      className={classes.backgroundImage}
    >
      <Box className={classes.rootBoxGradient}>
        <Container className={classes.rootContainer}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={4}
              className={classes.rootGridItem}
            >
              <Box mb={3}>
                <Typography variant="h1" component="h1" color="inherit">
                  Půjčovna kol
                </Typography>
              </Box>

              <Box mb={3}>
                <Typography component="div" variant="h5">
                  Provoz půjčovny kol byl ukončen.
                </Typography>
              </Box>

              <Box mb={3}>
                <List dense disablePadding>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <CirclePlusIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h6',
                        component: 'div',
                      }}
                      primary="Nejnovější modely"
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <CirclePlusIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h6',
                        component: 'div',
                      }}
                      primary="Doručení až domů"
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <CirclePlusIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h6',
                        component: 'div',
                      }}
                      primary="Helmy zdarma"
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <CirclePlusIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h6',
                        component: 'div',
                      }}
                      primary="Nabídka nosičů"
                    />
                  </ListItem>
                </List>
              </Box>

              <Box mb={3}>
                <BlackButton isSkewed size="large" onClick={handleScrollClick}>
                  Nabídka kol
                </BlackButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BackgroundImage>
  );
};

export default HomepageHeroSection;
