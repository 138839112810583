import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Bike } from '../types/Bike';

const DEFAULT_GET_QUERY_PARAMS = {};
const INITIAL_DATA_VALUE: Record<string, unknown> | [] = {};

const useQuery = (
  requestFunc: Promise<AxiosResponse> | unknown,
  params = DEFAULT_GET_QUERY_PARAMS,
  initialDataValue = INITIAL_DATA_VALUE,
) => {
  const [data, setData] = useState(initialDataValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      const requestData = async () => {
        const result = await requestFunc({ params });

        setData(result);
        setError(false);
        setLoading(false);
      };
      requestData();
    } catch (_error) {
      setError(true);
      setLoading(false);
    }
  }, [requestFunc, params]);

  return { data, loading, error };
};

export default useQuery;
