import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import Logo from '../components/Logo';
import SEO from '../components/SEO';
import { InfoSection } from '../sections/common';
import {
  HeroSection,
  HowIsItWorkSection,
  ListBikesSection,
} from '../sections/Homepage';

type DataProps = Record<string, unknown>;

const IndexPage: FC<PageProps<DataProps>> = () => {
  return (
    <Layout>
      <SEO title="Půjčovna kol BeRider" />

      <HeroSection />
      <div
        style={{
          position: 'absolute',
          right: '5%',
          top: '10vh',
        }}
      >
        <Logo type="wlc" />
      </div>
      <ListBikesSection />
      <HowIsItWorkSection />
      <InfoSection />
    </Layout>
  );
};

export default IndexPage;
