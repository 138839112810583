import axios, { AxiosRequestConfig } from 'axios';
import { Bike } from '../types/Bike';

const GET_BIKES_PATH = 'fetchBikes';

function addApiEnvPostfix(url: string) {
  return `${url}${process.env.GATSBY_API_ENV_POSTFIX}`;
}

const fbCloudApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5001/berider-internal/europe-west3'
      : 'https://europe-west3-berider-internal.cloudfunctions.net',
});

const getListBikes = async (config: AxiosRequestConfig): Promise<Bike[]> => {
  const removeDuplicatesBy = (
    keyFn: { (x: Bike): string; (arg0: Bike): string },
    arr: Bike[],
  ) => {
    const mySet = new Set();
    return arr.filter(function (x) {
      const key = keyFn(x),
        isNew = !mySet.has(key);
      if (isNew) mySet.add(key);
      return isNew;
    });
  };

  const result = await fbCloudApi.get(
    addApiEnvPostfix(`/${GET_BIKES_PATH}`),
    config,
  );

  const resultFiltered: Bike[] = removeDuplicatesBy((x: Bike) => {
    return x.modelId;
  }, result.data);

  return resultFiltered;
};

export { getListBikes };
