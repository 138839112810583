import { Box, Container, Link, Typography } from '@material-ui/core';
import React, { ChangeEvent, FC, PropsWithChildren, useState } from 'react';
import { BlackButton } from '../../components/Button';
import { CommentBox } from '../../components/CommentBox/CommentBox';
import FilterTab from '../../components/FilterTab';
import FilterTabContent from '../../components/FilterTabContent';
import FilterTabs from '../../components/FilterTabs';
import { BikeType } from '../../types/other';

type DataProps = Record<string, unknown>;
const BIKE_TYPES: BikeType[] = [
  {
    label: 'Vše',
    value: null,
  },
  {
    label: 'MTB full',
    value: 'mtbfull',
  },
  {
    label: 'Hardtail',
    value: 'mtbhardtail',
  },
  {
    label: 'e-Cross',
    value: 'ecross',
  },
  {
    label: 'Dětská',
    value: 'kids',
  },
];

const ListBikesSection: FC<PropsWithChildren<DataProps>> = () => {
  const [filterTabsValue, setFilterTabsValue] = useState(BIKE_TYPES[0].value);

  const handleFilterTabsChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: string,
  ) => {
    event.preventDefault();
    setFilterTabsValue(newValue);
  };

  return (
    <Box component="section" py={8} id="list-bikes-section">
      <Container>
        <Box mb={8}>
          <Typography component="h2" variant="h3" align="center">
            Kola k dispozici
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <FilterTabs
            value={filterTabsValue}
            onChange={handleFilterTabsChange}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="off"
            aria-label="filter tabs"
          >
            {BIKE_TYPES.map(({ label, value }) => (
              <FilterTab
                key={label.toLowerCase().replace(' ', '_')}
                label={label}
                value={value}
              />
            ))}
          </FilterTabs>
        </Box>

        {filterTabsValue ? <CommentBox categoryType={filterTabsValue} /> : null}

        <Box my={6}>
          <FilterTabContent type={filterTabsValue} />
        </Box>

        <Box textAlign="center" mt={6}>
          <BlackButton
            component={Link}
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=tgzrJjDRokuKtdDXmTrDG7xDVz4HycRIojWK05GGyQZUQUM0RVcwU0VKQkZJQ0RZUElURVJVNk43VS4u"
            target="_blank"
            rel="noopener noreferrer"
            isSkewed
            size="large"
          >
            Půjčit kolo
          </BlackButton>
        </Box>
      </Container>
    </Box>
  );
};

export default ListBikesSection;
