import { Box, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import useQuery from '../../hooks/useQuery';
import { getListBikes } from '../../services/apiService';
import { Bike } from '../../types/Bike';
import { BikeTypeValue } from '../../types/other';
import VerticalCard from '../Card/VerticalCard';

type DataProps = {
  type: string | null;
};
const INITIAL_FILTERS_DATA: [] = [];

function translateToFirestoreProp(type: BikeTypeValue) {
  switch (type) {
    case 'mtbfull':
      return 'MTB full';
    case 'mtbhardtail':
      return 'MTB hardtail';
    case 'ecross':
      return 'e-Cross';
    case 'kids':
      return 'Kids';
  }
}

const FilterTabContent: FC<PropsWithChildren<DataProps>> = (props: {
  type: BikeTypeValue;
}) => {
  const bikesQueryParams = useMemo(() => {
    const params = {
      typeGroup: translateToFirestoreProp(props.type),
    };
    return params;
  }, [props.type]);

  const { data, loading } = useQuery(
    getListBikes,
    bikesQueryParams,
    INITIAL_FILTERS_DATA,
  );

  if (loading) {
    return (
      <Grid container spacing={3}>
        {Array.from(new Array(8)).map((_item, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Box maxWidth={300} mx="auto">
              <Skeleton variant="rect" height={140} />
              <Skeleton variant="text" height={30} />
              <Skeleton variant="text" height={30} />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {data && data.length
        ? data.map((dataItem: Bike) => {
            return (
              <Grid key={dataItem.id} item xs={12} sm={6} md={4} lg={3}>
                <VerticalCard {...dataItem} />
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export default FilterTabContent;
