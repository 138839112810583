import { withStyles, Theme } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

const FilterTab = withStyles((theme: Theme) => ({
  root: {
    minWidth: 0,
    minHeight: 0,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    border: `1px solid ${theme.palette.common.black}`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),

    '&:hover, &:active, &:focus': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  textColorInherit: {
    opacity: 1,
  },
  selected: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
}))(Tab);

export default FilterTab;
